import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Icon } from "@iconify/react";
// import { AppContext } from "../../context/appContext";



// const { data } = useContext(AppContext);
// const [userData, setUserData] = useState();

const CurrentPlan = ({ setPlan }) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ paddingY: "2rem" }}>
        <Typography
          variant="h6"
          component="h6"
          fontSize="1rem"
          textAlign={"left"}
          marginTop="3rem"
        >
          Current Plan
        </Typography>
        <Divider
          sx={{
            height: "1px",
            background: "lightgrey",
            marginBottom: "2rem",
            marginTop: ".5rem",
          }}
        />

        <Stack direction="row" gap="5rem">
          <Stack direction="column">
            <Typography variant="h4" component="h4" fontSize={"1.25rem"}>
              Basic
            </Typography>
            <Typography variant="body" component="p" fontSize={"1.5rem"}>
              £20.00 per year
            </Typography>
            <Typography
              variant="body"
              component="p"
              sx={{ marginBottom: ".25rem" }}
            >
              Your plan renews on November 8, 2023.
            </Typography>

            <Box display="flex" alignItems="center" gap="1rem">
              <Icon icon="cib:cc-visa" color="#1976D2" fontSize={"2rem"} />
              <Typography variant="body">Visa .... 4242</Typography>
              <IconButton>
                <EditIcon />
              </IconButton>
            </Box>
          </Stack>

            {/* {data && data.role == "admin" ?( */}
          <Stack gap=".5rem">
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={() => setPlan("Upgrade")}
            >
              Update Plan
            </Button>
            <Button
              variant="contained"
              color="inherit"
              size="large"
              fullWidth
              onClick={() => setPlan("Cancel")}
            >
              Cancel
            </Button>
          </Stack>
            {/* ):("")} */}
        </Stack>
      </Box>
    </Box>
  );
};

export default CurrentPlan;
