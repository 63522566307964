import React, { useState, useEffect, useContext } from "react";
import "./EditProfileModal.css";
import axios from "axios";
// import { cloneDeep } from "lodash";
import { AppContext } from "../../context/appContext";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Modal,
  Button,
  Box,
  FormGroup,
  Typography,
  TextField,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 550,
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "lightgray",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 4,
  },
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  buttonn: {
    background: "#939393",
    color: "white",
    marginRight: 10,
    "&:hover": {
      background: "#888888",
    },
  },
  field: {
    marginBottom: 20,
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
    zIndex: 10,
  },
}));

export default function EditProfileModal({ open, close }) {
  const classes = useStyles();
  const { data, setData, handleToggle, updateMessage, getUserData } =
    useContext(AppContext);

  const initialProfileValidation = {
    name: false,
    imageUrl: false,
    newImage: false,
  };

  const [profile, setProfile] = useState({
    name: "",
    imageUrl: "",
    newImage: null,
  });

  const [profileValidations, setProfileValidations] = useState(
    initialProfileValidation
  );

  // To Handle Change &  Update Profile Picture
  const newPictureHandler = async (e) => {
    e.preventDefault();
    let image = e.target.files[0];
    setProfile({
      ...profile,
      newImage: image,
      imageUrl: URL.createObjectURL(image),
    });
  };

  // FormHandler For Update Profile
  const profileChangeHandler = (e) => {
    let { name, value } = e.target;

    setProfile({
      ...profile,
      [name]: value,
    });

    if (value && profileValidations[name]) {
      setProfileValidations({
        ...profileValidations,
        [name]: false,
      });
    }
  };

  const deleteProfilePicture = async () => {
    setProfile({
      ...profile,
      imageUrl: null,
      newImage: null,
    });
  };

  // To Update Profile
  const updateUser = async () => {
    let obj = {
      ...data,
      ...profile,
      notifyUser: false,
    };
    if (profile.newImage) {
      let formData = new FormData();
      formData.append("file", profile.newImage);
      try {
        handleToggle(true);
        await axios
          .put("general/profile", formData, {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            // console.log(response.data.data.imageUrl);
            obj.imageUrl = response.data.data.imageUrl;
          });
      } catch (e) {
        handleToggle(false);
        if (e.response && e.response.data) {
          updateMessage(e.response.data.message);
        }
      }
    }

    // console.log(data);
    if (data.role !== "learner") {
      try {
        handleToggle(true);
        await axios
          .put("faculty", obj, {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            close();
            handleToggle(false);
            getUserData();
            updateMessage(response.data.message);
          });
      } catch (e) {
        handleToggle(false);
        if (e.response && e.response.data) {
          updateMessage(e.response.data.message);
        }
      }
    } else {
      try {
        handleToggle(true);
        await axios
          .put("learner", obj, {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            close();
            handleToggle(false);
            getUserData();
            updateMessage(response.data.message);
          });
      } catch (e) {
        handleToggle(false);
        if (e.response && e.response.data) {
          updateMessage(e.response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    if (data) {
      setProfile({
        name: data.name,
        imageUrl: data.imageUrl,
      });
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle} className="modalContainer">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="modalHeader"
        >
          <Box sx={{ zIndex: 1 }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update Profile
            </Typography>
            <CloseIcon
              className={classes.icons}
              title="Close"
              onClick={close}
            />
          </Box>
        </Grid>

        <div className="profileImage">
          <div className="profileImageWrapper">
            <span>
              <Avatar
                alt={data && data.name}
                src={data && profile.imageUrl}
                sx={{ width: 100, height: 100 }}
              />
            </span>
            <input
              id="selectImage"
              name="imageUrl"
              accept="image/*"
              type="file"
              hidden
              onChange={(e) => newPictureHandler(e)}
            />
            <EditIcon
              className="editIcon"
              title="Edit"
              onClick={() => {
                document.getElementById("selectImage").click();
              }}
            />

            {profile?.imageUrl && (
              <DeleteIcon
                className="deleteIcon"
                title="Delete"
                onClick={() => {
                  deleteProfilePicture();
                }}
              />
            )}
          </div>
        </div>

        <FormGroup sx={{ paddingTop: 3 }}>
          <Grid container spacing={3} className={classes.field}>
            <Grid item sm={12} md={12} className="fullWidthInput">
              <TextField
                label="Name"
                variant="outlined"
                name="name"
                value={profile.name}
                onChange={(e) => profileChangeHandler(e)}
                error={profileValidations.name}
                helperText={profileValidations.name && "This can't be empty."}
              />
            </Grid>
          </Grid>
        </FormGroup>

        <div className="modalFooter">
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button onClick={updateUser} className={classes.button}>
              Save
            </Button>
            <Button
              onClick={close}
              className={classes.buttonn}
              style={{ marginRight: "1rem" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
