import React, { useEffect, useState, Fragment } from "react";
import "./LearnerInfoModal.css";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Modal,
  Button,
  Box,
  Typography,
  Drawer,
  useTheme,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "lightgray",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 4,
  },
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  icons: {
    cursor: "pointer",
  },
  indicator: {
    backgroundColor: "#5335ca",
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 550,
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};

export default function LearnerInfoModal(props) {
  const classes = useStyles();
  const [learnerFaculty, setLearnerFaculty] = useState({
    coach: "",
    mentor: "",
    trainer: "",
  });
  
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  // const handleDurationChange = (e, index) => {
  //   let { name, value } = e.target;
  //   let cloneDurations = cloneDeep(newLearner.monthlyHourMinutesUpdated);

  //   if (name === "hours") {
  //     if (value >= 0) {
  //       cloneDurations[index].targetHours = Number(value);
  //     } else {
  //       updateMessage("Hours can not be less than 0");
  //       return;
  //     }
  //   } else {
  //     if (value >= 0 && value < 60) {
  //       cloneDurations[index].targetMinutes = Number(value);
  //     } else {
  //       updateMessage("Minutes can not be greater than 59 or less than 0");
  //       return;
  //     }
  //   }

  //   // console.log(cloneDurations);
  //   setNewLearner({
  //     ...newLearner,
  //     monthlyHourMinutesUpdated: cloneDurations,
  //   });

  // };

  // const handleDurationChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedMonthlyHourMinutes = [...monthlyHourMinutes];
  //   const updatedDuration = { ...updatedMonthlyHourMinutes[index] };
  //   updatedDuration[name] = parseInt(value, 10);
  //   updatedMonthlyHourMinutes[index] = updatedDuration;
  //   setMonthlyHourMinutes(updatedMonthlyHourMinutes);
  // };

  return (
    <Drawer
      anchor="right"
      open={props.open}
      onClose={props.close}
      PaperProps={{
        sx: { width: matches ? "60%" : "100%" },
      }}
    >
      <Box sx={{ m: 2 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="drawerHeader"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Learner Profile
          </Typography>
          <CloseIcon
            className={classes.icons}
            title="Close"
            onClick={props.close}
          />
        </Grid>

        {props.userData && (
          <Fragment>
            <Paper>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                classes={{
                  indicator: classes.indicator,
                }}
              >
                <Tab label="Info" />
                <Tab label="Training Plan" />
              </Tabs>
            </Paper>

            {value === 0 ? (
              <div className="learnerProfileInfo">
                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    ULN (Unique Learner Number)
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData.uln ? props.userData.uln : "-"}
                  </Typography>
                </div>

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Name
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData.name}
                  </Typography>
                </div>

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Email
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData.email}
                  </Typography>
                </div>

                <hr />

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Apprenticeship Standard
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData.apprenticeshipStandard ? props.userData.apprenticeshipStandard : "-"}
                  </Typography>
                </div>

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Practical Period
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData?.monthlyHourMinutes?.length} weeks
                  </Typography>
                </div>

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Start and End Date
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData?.startDate} -{" "}
                  
                    {props.userData.endDate}
                  </Typography>
                </div>

                <hr />

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Employer Name
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData.employerName ? props.userData.employerName : "-"}
                  </Typography>
                </div>

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Training Provider
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData.trainingProviderName ? props.userData.trainingProviderName : "-"}
                  </Typography>
                </div>
              </div>
            ) : (
              <TableContainer
                sx={{
                  maxHeight: 450,
                  mt: 3,
                }}
              >
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      backgroundColor: "white",
                    }}
                  >
                    <TableRow>
                      <TableCell sx={{ fontSize: "18px", fontWeight: "600" }}>
                        Week
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "18px", fontWeight: "600" }}
                        align="center"
                      >
                        Hours
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "18px", fontWeight: "600" }}
                        align="center"
                      >
                        Minutes
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.userData.monthlyHourMinutes.map(
                      (duration, index) => (
                      <TableRow
                          key={duration?.weekName}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                        <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              fontSize: { xs: "11px", sm: "14px" },
                              width: { xs: "10rem", sm: "13rem" },
                              padding: { xs: "8px", sm: "16px" },
                            }}
                          >
                          {duration?.weekName} - ({duration.startDate} -{" "}
                          {duration.endDate})
                        </TableCell>
                        <TableCell
                            sx={{
                              width: { xs: "4rem", sm: "6rem" },
                              padding: { xs: "8px", sm: "16px" },
                            }}
                            align="center"
                          >
                            {
                          <input
                            type="number"
                            className="editHoursModalSignUp__table--input"
                            name="hours"
                            min="0"
                            value={duration?.targetHours}
                                                      />
}
                        </TableCell>
                        <TableCell
                            sx={{
                              width: { xs: "4rem", sm: "6rem" },
                              padding: { xs: "8px", sm: "16px" },
                              paddingRight: { xs: 0, sm: "16px" },
                            }}
                            align="center"
                          >
                            {
                          <input
                            type="number"
                            className="editHoursModalSignUp__table--input"
                            name="minutes"
                            min="0"
                            max="59"
                            value={duration?.targetMinutes}
                          />
}
                        </TableCell>
                      </TableRow>
                    )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Fragment>
        )}
      </Box>
    </Drawer>
  );
}
