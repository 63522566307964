import React, { useState, useContext } from "react";
import "./ProfileContainer.css";
import { Avatar, Box, Card, Typography, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppContext } from "../../context/appContext";
import TimeAgo from "react-timeago";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import InfoIcon from "@mui/icons-material/Info";
import LearnerInfoModal from "../LearnerInfoModal/LearnerInfoModal";
import { getHours } from "../../utils/functions";
// import Skeleton from '@mui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  divStyle: {
    display: "flex",
  },
  divStyle2: {
    display: "flex",
    justifyContent: "space-between",
  },
  divStylee: {
    display: "flex",
    padding: 10,
    justifyContent: "space-between",
  },
  field: {
    marginBottom: 20,
  },
  formStylee: {
    display: "flex",
    marginBottom: 20,
    marginTop: 10,
    justifyContent: "space-between",
  },
  boxStyle: {
    marginTop: 20,
    padding: 40,
    borderRadius: 10,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    "@media (max-width: 768px)": {
      padding: 20,
    },
  },
  boxStylePie: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
    width: "100%",
  },
  boxStyleLine: {
    marginTop: 20,
    padding: 40,
    borderRadius: 10,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    width: "100%",
  },
  cardStyle: {
    marginTop: 20,
    borderRadius: 5,
  },
  profileCard: {
    background: "#5335CA",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 10,
    padding: "20px",
  },
  profileCardText: {
    fontSize: 16,
    color: "white",
  },
  profileCardSubText: {
    fontSize: 12,
    color: "white",
  },
  text1: {
    marginTop: "50px",
    marginLeft: "25px",
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
  },
  button: {
    background: "#5335CA",
    "&:hover": {
      background: "#5335CA",
    },
  },
  typoText1: {
    fontFamily: "Poppins",
    fontSize: 9,
    fontWeight: 600,
  },
  qIcon: {
    height: "22.58px",
    top: "6px",
    width: "20px",
    position: "relative",
  },
  buttonn: {
    background: "#939393",
    marginRight: 10,
    "&:hover": {
      background: "#888888",
    },
  },
  form: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  label: {
    padding: "inherit",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  skeleton: {
    backgroundColor: "#fff",
    padding: "20px",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 10,
    marginTop: 20,
  },
}));

const ProfileContainer = (props) => {
  const classes = useStyles();
  // data, setData, handleToggle, updateMessage, getUserData
  const { data } = useContext(AppContext);
  const [showLearnerInfoModal, setShowLearnerInfoModal] = useState(false);
  const { userData } = props;

  return (
    <>
      <Box className={classes.cardStyle}>
        {data && userData ? (
          <Card className={classes.profileCard}>
            <Typography
              variant="h6"
              className={classes.profileCardText}
              style={{
                textTransform: "capitalize",
                borderBottom: "1px solid #fff",
                paddingBottom: "15px",
                marginBottom: "15px",
              }}
            >
              {data && userData.rawCompanyName}
            </Typography>

            <div className="learnerNameInfoWrapper">
              <Typography
                variant="h6"
                className={classes.profileCardText}
                style={{ textTransform: "capitalize" }}
              >
                {data &&
                  userData &&
                  (data._id === userData._id
                    ? "my"
                    : `${userData.name}'s`)}{" "}
                Profile
              </Typography>
              {userData.role === "learner" && (
                <InfoIcon
                  className="infoIcon"
                  onClick={() => setShowLearnerInfoModal(!showLearnerInfoModal)}
                />
              )}
            </div>

            {userData.role === "learner" && (
              <Typography className={classes.profileCardSubText}>
                {/* {userData.targetStatus} target by {userData.progress.differenceBetweenTargetPercentage}% out of {userData.progress.currentTargetPercentage}% */}
                {getHours(userData.progress.cureentProgress).hours}
                {"hr."}
                {getHours(userData.progress.cureentProgress).minutes}
                {"min"} completed out of{" "}
                {getHours(userData.progress.totalTarget).hours}
                {"hr."}
                {getHours(userData.progress.totalTarget).minutes}
                {"min"}
              </Typography>
            )}

            <div className={`profileCard__avatarWrapper ${classes.center}`}>
              {userData.role === "learner" ? (
                <CircularProgressbarWithChildren
                  value={userData.progress.currentCompletedPercentage}
                  maxValue={userData.trainingPercentage}
                  className={`CircularProgressbar-${userData.targetStatus}`}
                >
                  <Avatar
                    src={
                      data &&
                      userData &&
                      (data._id === userData._id
                        ? data.imageUrl
                        : userData.imageUrl)
                    }
                    alt={
                      data &&
                      userData &&
                      (data._id === userData._id ? data.name : userData.name)
                    }
                    style={{ width: "85%", height: "85%" }}
                  />
                </CircularProgressbarWithChildren>
              ) : (
                <Avatar
                  src={
                    data &&
                    userData &&
                    (data._id === userData._id
                      ? data.imageUrl
                      : userData.imageUrl)
                  }
                  alt={
                    data &&
                    userData &&
                    (data._id === userData._id ? data.name : userData.name)
                  }
                  style={{ width: "128px", height: "128px" }}
                />
              )}
            </div>

            <div
              style={{
                padding: "20px 0",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                // gap: ".5rem",
              }}
            >
              {/* <span style={{ marginBottom: ".5rem" }} display: { xs: 'block', md: 'none' }>
                <Typography variant="h6" className={classes.profileCardText} sx={}>
                  {userData ? userData.name : "No Name Available"}
                </Typography>
                <Typography
                  variant="h1"
                  className={classes.profileCardText}
                  style={{ textTransform: "capitalize" }}
                >
                  {userData && userData.role == "learner"
                    ? "Learner"
                    : userData.attribute}
                </Typography>
              </span> */}
              <Box
                sx={{
                  marginBottom: ".5rem",
                  display: { xs: "block", md: "none" },
                }}
              >
                <Typography variant="h6" className={classes.profileCardText}>
                  {userData ? userData.name : "No Name Available"}
                </Typography>
              </Box>
              {/* <Typography className={classes.profileCardSubText}>
                Last Activity:{" "}
                {userData.lastLogin ? (
                  <TimeAgo date={userData.lastLogin} />
                ) : (
                  "No Activity Yet"
                )}
              </Typography> */}
            </div>
          </Card>
        ) : (
          <div className={classes.skeleton}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton
              variant="circle"
              width={128}
              height={128}
              style={{ borderRadius: "50%", margin: "auto" }}
            />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </div>
        )}
      </Box>

      <LearnerInfoModal
        close={() => setShowLearnerInfoModal(false)}
        open={showLearnerInfoModal}
        userData={userData}
      />
    </>
  );
};

export default ProfileContainer;
