import React, { Fragment, useContext } from "react";
import "./HelpAndSupport.css";
// import {
//   Box,
//   Button,
//   Card,
//   CardActions,
//   CardContent,
//   FormGroup,
//   Modal,
//   TextareaAutosize,
//   Typography,
//   Skeleton,
// } from "@mui/material";
// import { makeStyles } from "@mui/styles";
// import axios from "axios";
// import SendIcon from "@mui/icons-material/Send";
import { AppContext } from "../../context/appContext";
// import Grid from "@mui/material/Grid";
// import CloseIcon from "@mui/icons-material/Close";
// import Skeleton from '@mui/lab/Skeleton';
// import { styled } from "@mui/material/styles";
// import LinearProgress, {
//   linearProgressClasses,
// } from "@mui/material/LinearProgress";
// import { capitalCase } from "capital-case";

// const useStyles = makeStyles(() => ({
//   cardStyle: {
//     marginTop: 20,
//     borderRadius: 10,
//   },
//   cardStyleHelpAndSupport: {
//     marginTop: 22,
//     borderRadius: 10,
//   },
//   button: {
//     background: "#5335CA",
//     "&:hover": {
//       background: "#5335CA",
//     },
//   },
//   buttonn: {
//     background: "#939393",
//     "&:hover": {
//       background: "#888888",
//     },
//     marginRight: 10,
//   },
//   textArea: {
//     outline: "none",
//     minWidth: 500,
//     maxWidth: "100%",
//     overflow: "auto !important",
//     maxHeight: 300,
//     padding: 10,
//     resize: "none",
//     marginBottom: 20,
//     borderRadius: 5,
//   },
//   icons: {
//     height: 14,
//     widht: 14,
//     color: "#5335CA",
//     cursor: "pointer",
//     zIndex: 10,
//   },
//   skeleton: {
//     backgroundColor: "#fff",
//     padding: "20px",
//     boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
//     borderRadius: 10,
//     marginTop: 20,
//   },
// }));

// const stylee = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   maxWidth: "95vw",
//   maxHeight: 550,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   borderRadius: 2,
//   p: 4,
// };

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 10,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor:
//       theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: theme.palette.mode === "light" ? "#5335CA" : "#5335CA",
//   },
// }));

const HelpAndSupport = (props) => {
  // const { data, handleToggle, updateMessage } = useContext(AppContext);
  const { data } = useContext(AppContext);

  // const classes = useStyles();

  const dataInGb = data?.usedStorage / (1024 * 1024 * 1024);

  // console.log("dataInGb:", dataInGb);

  function getValue(condition) {
    // console.log("condition:", condition);
    return condition === "basic" ? 5 : 20;
  }

  const percentage = (dataInGb / getValue(data?.subscription?.plan)) * 100;

  // console.log("Percentage", percentage);

  // const formatStorageSize = (bytes) => {
  //   // Define the thresholds for each storage unit
  //   const units = ["Bytes", "KB", "MB", "GB", "TB"];
  //   if (bytes == 0) return "0 Byte";
  //   const k = 1024;
  //   // Calculate the logarithm of the bytes to find the appropriate unit
  //   const i = Math.floor(Math.log(bytes) / Math.log(k));
  //   // Convert bytes to the appropriate unit and format the result
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + units[i];
  // };

  return (
    <Fragment>
      {/* <Fragment>
        {data ? (
          <Box className={classes.cardStyleHelpAndSupport}>
            <Card>
              <CardContent className="card-content">
                <Typography variant="h6" gutterBottom>
                  Subscription & Data
                </Typography>
                <Typography variant="body2">
                  {props.message}
                  {capitalCase(data?.subscription?.plan)}
                </Typography>
                <CardActions
                  sx={{ padding: 0, paddingTop: 1.2, paddingBottom: 1.2 }}
                >
                  <Typography sx={{ fontSize: "12px", pt: 1 }}>
                    Data storage usage - {formatStorageSize(data?.usedStorage)}{" "}
                    out of {data?.subscription.plan === "basic" ? "5" : "20"}GB
                  </Typography>
                </CardActions>
                <Box sx={{ flexGrow: 1 }}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={percentage}
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        ) : (
          <div className={classes.skeleton}>
            <Skeleton variant="text" height={45} />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="70%" height={45} />
          </div>
        )}
      </Fragment> */}
    </Fragment>
  );
};

export default HelpAndSupport;
