import React from "react";
import { Grid, Container, Typography, Stack, TextField } from "@mui/material";
import PricingPlanCard from "./PricingPlanCard";
import PricingPlanCustomCard from "./PricingPlanCustomCard";
import { useState } from "react";

const ChoosePlane = ({ pricing }) => {
  const [quantity, setQuantity] = useState(10);

  return (
    <Container maxWidth="xl">
      <Stack mb={8} direction="column">
        <Typography
          fontWeight={"bold"}
          variant="h4"
          align="center"
          paragraph
          gutterBottom
        >
          Subscription Plans for Your
          <br /> Off-The-Job Training Web App
        </Typography>
      </Stack>
      <Grid
        container
        spacing={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {pricing.map((card, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            key={card.id}
            display="flex"
            justifyContent="center"
          >
            <PricingPlanCard card={card} index={index} quantity={quantity} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ChoosePlane;
