import React, { useState, useEffect, useContext, Fragment } from "react";
import "./AddLearner.css";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import axios from "axios";
import moment from "moment";
import { cloneDeep } from "lodash";
import { AppContext } from "../../context/appContext";
import { makeStyles } from "@mui/styles";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import ClearIcon from "@mui/icons-material/Clear";

// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";

// import dayjs from 'dayjs';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import enLocale from "date-fns/locale/en-GB";
import {
  formatMinutesInOOOO,
  formatSecondsInOOOO,
  // getChangeAbleIndex,
  getDaysBetweenTwoDates,
  // getPlanHoursMinutes,
  // getWeeksBetweenTwoDates,
  validateEmail,
} from "../../utils/functions";
import jwt from "jwt-decode";
import {
  Alert,
  // Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  useTheme,
} from "@mui/material";
import { getLearners, setAllLearners } from "../../redux/slices/learner";
import { useDispatch, useSelector } from "../../redux/store";
import calculateLearnerHours from "../../utils/calculateLearnerHours";
import { useParams } from "react-router-dom";
// import autoCalculateDurations from "../../utils/autoCalculateDurations";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  // overflow: "auto",
  borderRadius: 2,
  p: 4,
};

const useStyles = makeStyles(() => ({
  divStyle: {
    display: "flex",
  },
  divStyle2: {
    display: "flex",
    justifyContent: "space-between",
  },

  field: {
    marginBottom: 20,
  },
  boxStyle: {
    marginTop: 20,
    padding: 40,
    borderRadius: 10,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    "@media (max-width: 768px)": {
      padding: 20,
    },
  },
  cardStyle: {
    marginTop: 20,
    borderRadius: 10,
  },
  profileCard: {
    background: "#5335CA",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 10,
  },
  profileCardText: {
    color: "white",
    fontSize: 16,
  },

  profileCardText1: {
    color: "white",
    fontSize: 12,
  },
  text1: {
    marginTop: "50px",
    marginLeft: "25px",
  },
  text2: {
    marginTop: "30px",
    // marginLeft: "25px",
  },
  text1Sub: {
    fontSize: "1.1rem",
  },
  typoText1: {
    fontSize: 9,
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
    zIndex: 10,
  },
  qIcon: {
    height: "22.58px",
    top: "6px",
    width: "20px",
    position: "relative",
  },
  button: {
    background: "#5335CA",
    "&:hover": {
      background: "#5335CA",
    },
  },
  buttonn: {
    background: "#939393",
    "&:hover": {
      background: "#888888",
    },
    marginRight: 10,
  },
  form: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  label: {
    padding: "inherit",
  },
  textArea: {
    outline: "none",
    minWidth: 500,
    maxWidth: "100%",
    // overflow: "auto !important",
    maxHeight: 300,
    padding: 10,
    resize: "none",
    marginBottom: 20,
    borderRadius: 5,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
}));

const AddLearner = ({ openAddLearner, toggleAddNewLearner }) => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const classes = useStyles();
  // data, setData, handleToggle, updateMessage, getUserData
  const { data, handleToggle, updateMessage } = useContext(AppContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { allFaculties } = useSelector((state) => state.faculty);
  const [filteredFaculties, setFilteredFaculties] = useState({
    coach: [],
    manager: [],
    mentor: [],
    visitor: [],
    trainer: [],
  });

  /* VARIABLES */
  const newLearnerInitial = {
    uln: "",
    name: "",
    email: "",
    trainingProviderName: "",
    coachMentor: [],
    coach: "",
    manager: "",
    mentor: "",
    trainer: "",
    employerName: "",
    apprenticeshipStandard: "",
    startDate: new Date(),
    endDate: new Date(),
    // endDate: new Date(),
    // durationInMonths: "",
    durationInHours: "",
    // durationInWeeks: "",
    // trainingPercentage: 20,
    // minHoursPerWeek: 6,
    // targetMonths: 3,
    totalMinutes: 0,
    emailToMentor: true,
    emailToCoach: true,
    monthlyHourMinutes: [],
    monthlyHourMinutesUpdated: [],
  };
  const newLearnerValidations = {
    // uln: false,
    name: false,
    email: false,
    trainingProviderName: false,
    employerName: false,
    apprenticeshipStandard: false,
    startDate: false,
    endDate: false,
    // durationInMonths: false,
    durationInHours: false,
    // durationInWeeks: false,
    // trainingPercentage: false,
    // minHoursPerWeek: false,
    // targetMonths: false,
  };

  /* STATES */
  // For New Learner Modal
  const [newLearner, setNewLearner] = useState(newLearnerInitial);
  const [learnerValidations, setLearnerValidations] = useState(
    newLearnerValidations
  );
  const [disableReset, setDisableReset] = useState(true);

  // For Reset Modal
  const [resetModal, setResetModal] = useState(false);
  const [changedSeconds, setChangedSeconds] = useState(0);

  // For Subscription Redirect Modal
  const [redirectSubscriptionModal, setRedirectSubscriptionModal] =
    useState(false);

  /* FUNCTIONS */

  // FormHandler For Add New Learner Modal
  const newLearnerChangeHandler = (e, selectInput) => {
    if (e && e.target && e.target.name) {
      let { name, value } = e.target;

      if (
        // name === "trainingPercentage" ||
        // name === "minHoursPerWeek" ||
        name === "durationInHours"
        // name === "durationInMonths" ||
        // name === "durationInWeeks"
      ) {
        if (value >= 1) {
          setNewLearner({
            ...newLearner,
            [name]: Number(value),
          });
          if (value && learnerValidations[name]) {
            setLearnerValidations({
              ...learnerValidations,
              [name]: false,
            });
          }
        }
      } else if (name === "email") {
        setNewLearner({
          ...newLearner,
          [name]: value,
        });

        if (value && learnerValidations[name]) {
          setLearnerValidations({
            ...learnerValidations,
            [name]: false,
          });
        }
      } else {
        setNewLearner({
          ...newLearner,
          [name]: value,
        });
        if (value && learnerValidations[name]) {
          setLearnerValidations({
            ...learnerValidations,
            [name]: false,
          });
        }
      }
    } else if (
      e &&
      (selectInput === "startDate" || selectInput === "endDate")
    ) {
      setNewLearner({
        ...newLearner,
        [selectInput]: e.$d,
      });
      if (e && learnerValidations[selectInput]) {
        setLearnerValidations({
          ...learnerValidations,
          [selectInput]: false,
        });
      }
    } else if (
      e &&
      (selectInput === "coach" ||
        selectInput === "manager" ||
        selectInput === "mentor" ||
        selectInput === "trainer")
    ) {
      setNewLearner({
        ...newLearner,
        [selectInput]: e,
      });
    }
  };

  // FormHandler For Add New Learner Modal CheckBoxes
  const newLearnerCheckboxHandler = (e) => {
    let { name } = e.target;

    setNewLearner({
      ...newLearner,
      [name]: !newLearner[name],
    });
  };

  // FormHandler For Reset Learner Durations Modal
  const handleDurationChange = (e, index) => {
    let { name, value } = e.target;
    let cloneDurations = cloneDeep(newLearner.monthlyHourMinutesUpdated);

    if (name === "hours") {
      // cloneDurations[index].targetHours = Number(value);
      if (value >= 0) {
        cloneDurations[index].targetHours = Number(value);
      } else {
        updateMessage("Hours can not be less than 0");
        return;
      }
    } else {
      if (value >= 0 && value < 60) {
        cloneDurations[index].targetMinutes = Number(value);
      } else {
        updateMessage("Minutes can not be greater than 59 or less than 0");
        return;
      }
    }

    // console.log(cloneDurations);
    setNewLearner({
      ...newLearner,
      monthlyHourMinutesUpdated: cloneDurations,
    });

    // let newCloneDuration = autoCalculateDurations(
    //   index,
    //   cloneDurations,
    //   newLearner
    // );

    // console.log(newCloneDuration);

    // if (newCloneDuration.error) {
    //   updateMessage(newCloneDuration.error);
    // } else {
    //   setNewLearner({
    //     ...newLearner,
    //     monthlyHourMinutesUpdated: newCloneDuration.cloneDuration,
    //   });
    // }
  };

  // To calculate the no. of days between two dates
  // const daysBetween = (first, second) => {
  //   // Copy date parts of the timestamps, discarding the time parts.
  //   var one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
  //   var two = new Date(
  //     second.getFullYear(),
  //     second.getMonth(),
  //     second.getDate()
  //   );

  //   // Do the math.
  //   var millisecondsPerDay = 1000 * 60 * 60 * 24;
  //   var millisBetween = two.getTime() - one.getTime();
  //   var days = millisBetween / millisecondsPerDay;

  //   // Round down.
  //   return Math.floor(days);
  // };

  // To Reset The Add New Learner Modal
  const resetAddNewLearner = () => {
    setNewLearner(newLearnerInitial);
    setLearnerValidations(newLearnerValidations);
  };

  // To Open The Reset Durations Modal
  const toggleResetDurations = () => {
    setResetModal(!resetModal);
  };

  // To Add A New Learner
  const addNewLearner = async () => {
    // 1- Validations

    if (newLearner.name === "") {
      setLearnerValidations({ ...learnerValidations, name: true });
      return;
    }
    if (newLearner.email === "" || !validateEmail(newLearner.email)) {
      setLearnerValidations({ ...learnerValidations, email: true });
      return;
    }
    if (newLearner.trainingProviderName === "") {
      setLearnerValidations({
        ...learnerValidations,
        trainingProviderName: true,
      });
      return;
    }
    if (newLearner.employerName === "") {
      setLearnerValidations({ ...learnerValidations, employerName: true });
      return;
    }
    if (newLearner.apprenticeshipStandard === "") {
      setLearnerValidations({
        ...learnerValidations,
        apprenticeshipStandard: true,
      });
      return;
    }
    if (newLearner.startDate === "") {
      setLearnerValidations({ ...learnerValidations, startDate: true });
      return;
    }
    if (
      newLearner.endDate === "" ||
      moment(newLearner.endDate).isBefore(newLearner.startDate) ||
      getDaysBetweenTwoDates(newLearner.startDate, newLearner.endDate) < 364
    ) {
      setLearnerValidations({ ...learnerValidations, endDate: true });
      return;
    }

    if (newLearner.durationInHours === "") {
      setLearnerValidations({ ...learnerValidations, durationInHours: true });
      return;
    }

    if (newLearner.minHoursPerWeek === "") {
      setLearnerValidations({
        ...learnerValidations,
        minHoursPerWeek: true,
      });
      return;
    }

    if (newLearner.targetMonths === "") {
      setLearnerValidations({
        ...learnerValidations,
        targetMonths: true,
      });
      return;
    }

    // 2- Calculations
    // let learner = calculateLearnerHours();

    let coachMentor = allFaculties.filter(
      (faculty) =>
        faculty.email === newLearner.coach ||
        faculty.email === newLearner.manager ||
        faculty.email === newLearner.mentor ||
        faculty.email === newLearner.trainer
    );

    // 3- Api
    try {
      // console.log(newLearner);
      let updatedLearner = {
        ...cloneDeep(newLearner),
        startDate: newLearner.startDate.toLocaleDateString("en-GB", {
          timeZone: "Europe/London",
        }),
        endDate: newLearner.endDate.toLocaleDateString("en-GB", {
          timeZone: "Europe/London",
        }),
        coachMentor,
      };

      // console.log(updatedLearner);

      handleToggle(true);
      await axios
        .post("learner/register", updatedLearner, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setNewLearner({
            ...newLearner,
            uln: "",
            name: "",
            email: "",
          });
          handleToggle(false);
          // dispatch(setAllLearners(response.data.data));
          dispatch(getLearners(id));
          updateMessage(response.data.message);
        });
    } catch (e) {
      handleToggle(false);

      if (e.response && e.response.status === 403) {
        setRedirectSubscriptionModal(!redirectSubscriptionModal);
      } else if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  // To Calculate The Changed Durations Of New Learner
  useEffect(() => {
    if (
      newLearner &&
      newLearner.monthlyHourMinutesUpdated &&
      newLearner.monthlyHourMinutesUpdated.length > 0
    ) {
      let changedHours = newLearner.monthlyHourMinutesUpdated.map(
        (x) => x.targetHours
      );
      let changedMinutes = newLearner.monthlyHourMinutesUpdated.map(
        (x) => x.targetMinutes
      );

      let changedSeconds;
      changedHours = changedHours.reduce((a, b) => a + b, 0);
      changedMinutes = changedMinutes.reduce((a, b) => a + b, 0);
      // console.log(
      //   "changedHours",
      //   changedHours,
      //   "changedMinutes",
      //   changedMinutes
      // );
      changedSeconds = Math.floor(changedHours * 3600 + changedMinutes * 60);
      // console.log("Changed Seconds", changedSeconds);
      setChangedSeconds(changedSeconds);
    }
  }, [newLearner]); // eslint-disable-line react-hooks/exhaustive-deps

  // To Enable/ Disable Reset Durations Modal Button
  useEffect(() => {
    if (
      newLearner.startDate &&
      newLearner.endDate &&
      newLearner.durationInHours
      // newLearner.trainingPercentage
    ) {
      // let learner = calculateLearnerHours(newLearner);
      // setNewLearner(learner);
      calculateLearnerHoursHandler();
      setDisableReset(false);
    } else {
      setDisableReset(true);
    }
  }, [
    newLearner.startDate,
    newLearner.endDate,
    // newLearner.durationInWeeks,
    // newLearner.minHoursPerWeek,
    // newLearner.durationInMonths,
    // newLearner.durationInHours,
    // newLearner.trainingPercentage,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  // To Set/ Update The Filtered Faculties When 'faculties' State Changes
  useEffect(() => {
    if (allFaculties && allFaculties.length > 0) {
      let coach = [],
        manager = [],
        mentor = [],
        visitor = [],
        trainer = [];

      for (let faculty of allFaculties) {
        if (faculty?.role === "coach") {
          coach.push(faculty);
        } else if (faculty?.role === "manager") {
          manager.push(faculty);
        } else if (faculty?.role === "mentor") {
          mentor.push(faculty);
        } else if (faculty?.role === "visitor") {
          visitor.push(faculty);
        } else if (faculty?.role === "trainer") {
          trainer.push(faculty);
        }
      }
      setFilteredFaculties({
        coach,
        manager,
        mentor,
        visitor,
        trainer,
      });
    }
  }, [allFaculties]);

  const updateHoursAndMinutes = () => {
    if (changedSeconds / 60 === newLearner.totalMinutes) {
      setResetModal(!resetModal);

      let monthlyHourMinutes = cloneDeep(newLearner.monthlyHourMinutesUpdated);

      setNewLearner({
        ...newLearner,
        monthlyHourMinutes,
      });
    } else {
      updateMessage("TOTAL TIME and UPDATED TIME should be equal");
    }
  };

  // To Redirect to Stripe For payment
  const redirectToStripe = async () => {
    let token = localStorage.getItem("7-star-training");
    let decodedToken = jwt(token.replace("Bearer ", "")); // decode your token here
    if (data.tenant.subscription.plan === "custom") {
      try {
        handleToggle(true);
        await axios
          .get("faculty/stripe/session", {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            handleToggle(false);
            // window.location.replace(response.data.data.url);
            // window.open(response.data.data.url, "_blank");
            window.location.href = response.data.data.url;

            setRedirectSubscriptionModal(!redirectSubscriptionModal);
          });
      } catch (e) {
        handleToggle(false);
        if (e.response && e.response.data) {
          updateMessage(e.response.data.message);
        }
      }
    } else {
      try {
        handleToggle(true);
        await axios
          .post(
            "saas/billing",
            { company: decodedToken.company },
            {
              config: { handlerEnabled: true },
            }
          )
          .then((response) => {
            handleToggle(false);
            // window.location.href = response.data.data.url;
            // window.open(response.data.data.url, "_blank");
            window.location.href = response.data.data.url;

            setRedirectSubscriptionModal(!redirectSubscriptionModal);
            // setSubscriptionData(response.data.data);
            // setShowSubscription(!showSubscription);
            // handleToggle(false);
          });
      } catch (e) {
        handleToggle(false);
        if (e.response && e.response.data) {
          updateMessage(e.response.data.message);
        }
      }
    }
  };

  const getTimeDifference = () => {
    let seconds =
      Number(changedSeconds) - Number(newLearner?.totalMinutes * 60);
    seconds = Math.abs(seconds);
    // console.log(seconds);
    return formatSecondsInOOOO(seconds);
  };

  const calculateLearnerHoursHandler = () => {
    let learner = calculateLearnerHours(newLearner);
    // console.log("LEARNER", learner);
    setNewLearner(learner);
  };

  return (
    <Fragment>
      {/* Add New Learner Drawer */}
      <Drawer
        anchor="right"
        open={openAddLearner}
        onClose={toggleAddNewLearner}
        PaperProps={{
          sx: { width: matches ? "40%" : "100%" },
        }}
      >
        <Box sx={{ m: 2 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="drawerHeader"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create New Learner
            </Typography>
            <CloseIcon
              className={classes.icons}
              title="Close"
              onClick={toggleAddNewLearner}
            />
          </Grid>
          <FormGroup sx={{ paddingTop: 3 }}>
            <TextField
              label="ULN (Unique Learner Number)"
              variant="outlined"
              name="uln"
              className={classes.field}
              value={newLearner.uln}
              onChange={(e) => newLearnerChangeHandler(e)}
              error={learnerValidations.uln}
              helperText={learnerValidations.uln && "This can't be empty."}
            />
            <Grid container spacing={3} className={classes.field}>
              <Grid item sm={12} md={12} className="fullWidthInput">
                <TextField
                  label="Name"
                  variant="outlined"
                  name="name"
                  className="fullWidthInput"
                  value={newLearner.name}
                  onChange={(e) => newLearnerChangeHandler(e)}
                  error={learnerValidations.name}
                  helperText={learnerValidations.name && "This can't be empty."}
                />
              </Grid>
            </Grid>
            <TextField
              label="Work Email"
              variant="outlined"
              className={classes.field}
              type="email"
              name="email"
              value={newLearner.email}
              onChange={(e) => newLearnerChangeHandler(e)}
              error={learnerValidations.email}
              helperText={
                learnerValidations.email && "This must be in email format."
              }
            />
            <TextField
              label="Training Provider Name"
              variant="outlined"
              className={classes.field}
              type="email"
              name="trainingProviderName"
              value={newLearner.trainingProviderName}
              onChange={(e) => newLearnerChangeHandler(e)}
              error={learnerValidations.trainingProviderName}
              helperText={
                learnerValidations.trainingProviderName &&
                "This can't be empty."
              }
            />

            <TextField
              label="Employer Name"
              variant="outlined"
              className={classes.field}
              name="employerName"
              value={newLearner.employerName}
              onChange={(e) => newLearnerChangeHandler(e)}
              error={learnerValidations.employerName}
              helperText={
                learnerValidations.employerName && "This can't be empty."
              }
            />

            {/* <Autocomplete
              className={classes.field}
              options={filteredFaculties.coach}
              getOptionLabel={(option) => option.email}
              onChange={(e) => newLearnerChangeHandler(e, "coach")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "disabled",
                  }}
                  label="Coach Email"
                  variant="outlined"
                />
              )}
            /> */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Coach</InputLabel>
              <Select
                className={classes.field}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newLearner.coach}
                label="Coach"
                onChange={(e) =>
                  newLearnerChangeHandler(e.target.value, "coach")
                }
                endAdornment={
                  <IconButton
                    sx={{ visibility: newLearner.coach ? "visible" : "hidden" }}
                    onClick={() => {
                      setNewLearner({
                        ...newLearner,
                        coach: "",
                      });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                }
                sx={{
                  "& .MuiSelect-iconOutlined": {
                    display: newLearner.coach ? "none" : "",
                  },
                }}
              >
                {filteredFaculties.coach.map((option) => (
                  <MenuItem value={option.email}>{option.email}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <Autocomplete
              className={classes.field}
              options={filteredFaculties.manager}
              getOptionLabel={(option) => option.email}
              onChange={(e) => newLearnerChangeHandler(e, "manager")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "disabled",
                  }}
                  label="Manager Email"
                  variant="outlined"
                />
              )}
            /> */}

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Manager</InputLabel>
              <Select
                className={classes.field}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newLearner.manager}
                label="Manager"
                onChange={(e) =>
                  newLearnerChangeHandler(e.target.value, "manager")
                }
                endAdornment={
                  <IconButton
                    sx={{
                      visibility: newLearner.manager ? "visible" : "hidden",
                    }}
                    onClick={() => {
                      setNewLearner({
                        ...newLearner,
                        manager: "",
                      });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                }
                sx={{
                  "& .MuiSelect-iconOutlined": {
                    display: newLearner.manager ? "none" : "",
                  },
                }}
              >
                {filteredFaculties.manager.map((option) => (
                  <MenuItem value={option.email}>{option.email}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <Autocomplete
              className={classes.field}
              options={filteredFaculties.mentor}
              getOptionLabel={(option) => option.email}
              onChange={(e) => newLearnerChangeHandler(e, "mentor")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "disabled",
                  }}
                  label="Mentor Email"
                  variant="outlined"
                />
              )}
            /> */}

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Mentor</InputLabel>
              <Select
                className={classes.field}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newLearner.mentor}
                label="Mentor"
                onChange={(e) =>
                  newLearnerChangeHandler(e.target.value, "mentor")
                }
                endAdornment={
                  <IconButton
                    sx={{
                      visibility: newLearner.mentor ? "visible" : "hidden",
                    }}
                    onClick={() => {
                      setNewLearner({
                        ...newLearner,
                        mentor: "",
                      });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                }
                sx={{
                  "& .MuiSelect-iconOutlined": {
                    display: newLearner.mentor ? "none" : "",
                  },
                }}
              >
                {filteredFaculties.mentor.map((option) => (
                  <MenuItem value={option.email}>{option.email}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Trainer</InputLabel>
              <Select
                className={classes.field}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newLearner.trainer}
                label="Trainer"
                onChange={(e) =>
                  newLearnerChangeHandler(e.target.value, "trainer")
                }
                endAdornment={
                  <IconButton
                    sx={{
                      visibility: newLearner.trainer ? "visible" : "hidden",
                    }}
                    onClick={() => {
                      setNewLearner({
                        ...newLearner,
                        trainer: "",
                      });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                }
                sx={{
                  "& .MuiSelect-iconOutlined": {
                    display: newLearner.trainer ? "none" : "",
                  },
                }}
              >
                {filteredFaculties.trainer.map((option) => (
                  <MenuItem value={option.email}>{option.email}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Apprenticeship Standard"
              variant="outlined"
              className={classes.field}
              name="apprenticeshipStandard"
              value={newLearner.apprenticeshipStandard}
              onChange={(e) => newLearnerChangeHandler(e)}
              error={learnerValidations.apprenticeshipStandard}
              helperText={
                learnerValidations.apprenticeshipStandard &&
                "This can't be empty."
              }
            />

            <Alert className={classes.field} severity="info">
              Duration should be minimum of 1 year
            </Alert>

            <Grid container spacing={3} className={classes.field}>
              <Grid item sm={12} md={6} className="fullWidthInput">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  locale={enLocale}
                >
                  <DatePicker
                    label="Start Date"
                    name="startDate"
                    value={newLearner.startDate}
                    showToolbar={false}
                    // maxDate = {newLearner.endDate}
                    onChange={(e) => newLearnerChangeHandler(e, "startDate")}
                    // onSelect={(e) => newLearnerChangeHandler(e, "startDate")}
                    error={learnerValidations.startDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                    format="DD-MM-YYYY"
                    inputFormat="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item sm={12} md={6} className="fullWidthInput">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  locale={enLocale}
                >
                  <DatePicker
                    label=" End Date"
                    name="endDate"
                    value={newLearner.endDate}
                    showToolbar={false}
                    // minDate = {newLearner.startDate}
                    onChange={(e) => newLearnerChangeHandler(e, "endDate")}
                    onSelect={(e) => newLearnerChangeHandler(e, "endDate")}
                    error={learnerValidations.endDate}
                    // helperText={
                    //   learnerValidations.endDate &&
                    //   "This can't be empty."
                    // }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onKeyDown={(e) => e.preventDefault()}
                        error={learnerValidations.endDate}
                        helperText={
                          learnerValidations.endDate &&
                          "End date can not be less than start date and the minimum duration should be 1 year."
                        }
                      />
                    )}
                    format="DD-MM-YYYY"
                    inputFormat="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <TextField
              label="Planned OTJ Training Hours"
              variant="outlined"
              className={classes.field}
              type="number"
              name="durationInHours"
              value={newLearner.durationInHours}
              onChange={(e) => newLearnerChangeHandler(e)}
              error={learnerValidations.durationInHours}
              helperText={
                learnerValidations.durationInHours && "This can't be empty."
              }
            />

            {/* <TextField
              label="Duration (in months)"
              variant="outlined"
              className={classes.field}
              type="number"
              name="durationInMonths"
              value={newLearner.durationInMonths}
              onChange={(e) => newLearnerChangeHandler(e)}
              error={learnerValidations.durationInMonths}
              helperText={
                learnerValidations.durationInMonths && "This can't be empty."
              }
            /> */}

            {/* <TextField
              label="Total OTJ Training Weeks"
              variant="outlined"
              className={classes.field}
              type="number"
              name="durationInWeeks"
              value={newLearner.durationInWeeks}
              onChange={(e) => newLearnerChangeHandler(e)}
              error={learnerValidations.durationInWeeks}
              helperText={
                learnerValidations.durationInWeeks && "This can't be empty."
              }
            /> */}

            {/* <TextField
              label="Minimum OTJ Training Hours Per Week"
              variant="outlined"
              className={classes.field}
              type="number"
              name="minHoursPerWeek"
              value={newLearner.minHoursPerWeek}
              onChange={(e) => newLearnerChangeHandler(e)}
              error={learnerValidations.minHoursPerWeek}
              helperText={
                learnerValidations.minHoursPerWeek && "This can't be empty."
              }
            /> */}

            {/* <TextField
              label="Reset Over Target Weeks"
              variant="outlined"
              className={classes.field}
              type="number"
              name="targetMonths"
              value={newLearner.targetMonths}
              onChange={(e) => newLearnerChangeHandler(e)}
              error={learnerValidations.targetMonths}
              helperText={
                learnerValidations.targetMonths && "This can't be empty."
              }
            /> */}

            <FormControlLabel
              control={
                <Checkbox
                  checked={newLearner.emailToMentor}
                  onChange={(e) => newLearnerCheckboxHandler(e)}
                  name="emailToMentor"
                />
              }
              label="Email confirmation to mentor"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={newLearner.emailToCoach}
                  onChange={(e) => newLearnerCheckboxHandler(e)}
                  name="emailToCoach"
                />
              }
              label="Email confirmation to coach"
            />
          </FormGroup>

          <div className="drawerFooter">
            <div
              style={{
                // paddingTop: "10px",
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                variant="contained"
                className={classes.button}
                onClick={addNewLearner}
              >
                Submit
              </Button>

              <Button
                variant="contained"
                disabled={disableReset}
                className={classes.buttonn}
                onClick={toggleResetDurations}
              >
                Training Plan
              </Button>

              <Button
                variant="contained"
                className={classes.buttonn}
                onClick={resetAddNewLearner}
              >
                Reset
              </Button>
            </div>
          </div>
        </Box>
      </Drawer>
      {/* </Modal> */}

      {/* Reset New Learner Durations Modal */}
      <Modal
        open={resetModal}
        onClose={() => setResetModal(!resetModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} className="modalContainer">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="modalHeader"
          >
            <Typography variant="h6" component="h3" sx={{ flexGrow: 1 }}>
              Reset Training Plan
            </Typography>
            <CloseIcon
              className={classes.icons}
              title="Close"
              onClick={() => setResetModal(!resetModal)}
            />
          </Grid>

          <form className="editHoursModalSignUp__form">
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="editHoursModalSignUp__table--wrapper">
                    <div className="tablefixes">
                      <table className="editHoursModalSignUp__table ">
                        <tbody>
                          <tr className="editHoursModalSignUp__table--row editHoursModalSignUp__table--row-heading">
                            <th colspan="2">Weeks</th>
                            <th>Hours</th>
                            <th>Minutes</th>
                            <th></th>
                          </tr>

                          {newLearner &&
                          newLearner.monthlyHourMinutesUpdated &&
                          newLearner.monthlyHourMinutesUpdated.length > 0 ? (
                            newLearner.monthlyHourMinutesUpdated.map(
                              (duration, index) => (
                                <tr
                                  className="editHoursModalSignUp__table--row"
                                  key={index}
                                >
                                  <td>
                                    <span>
                                      {duration?.weekName} - (
                                      {duration.startDate} - {duration.endDate})
                                    </span>
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="editHoursModalSignUp__table--input"
                                      name="hours"
                                      min="0"
                                      value={duration?.targetHours}
                                      onChange={(e) =>
                                        handleDurationChange(e, index)
                                      }
                                      // onKeyPress={(e) => handleKeyPress(e, index)}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      type="number"
                                      className="editHoursModalSignUp__table--input"
                                      name="minutes"
                                      min="0"
                                      max="59"
                                      value={duration?.targetMinutes}
                                      onChange={(e) =>
                                        handleDurationChange(e, index)
                                      }
                                      // onKeyPress={(e) => handleKeyPress(e, index)}
                                    />
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <Alert severity="error">
                              Please Select a Valid Start and End Date!
                            </Alert>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="modalFooter">
            {/* <Divider /> */}
            <Stack
              // direction="row"
              // margin="10px"
              // padding="15px"
              // paddingBottom="15px"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={{ xs: 2 }}
              color="rgba(0, 0, 0, 0.8)"
              // gap="4.6rem"
              border=" 1px solid rgba(0,0,0,0.23)"
              borderRadius="5px"
              // className="editHoursModalSignUp__table--row"
              sx={{ padding: 1, marginBottom: 1 }}
            >
              <span>
                <p className="fontFixe">
                  Total Time: {formatMinutesInOOOO(newLearner?.totalMinutes)}
                </p>
              </span>

              <span>
                <p className="fontFixe">
                  Updated Time: {formatSecondsInOOOO(changedSeconds)}
                </p>
              </span>

              <span>
                <p className="fontFixe">
                  Time Difference: {getTimeDifference()}
                </p>
              </span>
            </Stack>
            <Stack
              direction="row-reverse"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <span>
                <Button
                  variant="contained"
                  className={classes.buttonn}
                  onClick={() => {
                    // calculateLearnerHours();
                    setResetModal(!resetModal);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={updateHoursAndMinutes}
                >
                  Save
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  className={classes.button}
                  onClick={calculateLearnerHoursHandler}
                >
                  <RotateLeftIcon />
                </Button>
              </span>
            </Stack>
          </div>
        </Box>
      </Modal>

      {/* Subscription Redirect Modal */}
      <ConfirmationModal
        open={redirectSubscriptionModal}
        close={() => setRedirectSubscriptionModal(!redirectSubscriptionModal)}
        title="Upgrade your plan"
        text="Please upgrade your subscription plan to add more learners."
        ctaTitleText="Upgrade"
        cta={redirectToStripe}
      />
    </Fragment>
  );
};

export default AddLearner;
